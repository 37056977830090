<template>
  <div class="page-content">
    <div class="card row">
      <Whatsapp v-if="activeChannel.type === 'whatsapp'"/>
      <Whatsapp_personal v-if="activeChannel.type === 'whatsapp_personal'"/>
      <Sms v-if="activeChannel.type === 'sms'"/>
    </div>
  </div>
</template>
<script>
import Whatsapp from '../../components/create-template/Whatsapp.vue';
import Whatsapp_personal from '../../components/create-template/WhatsappPersonal.vue';
import Sms from '../../components/create-template/Sms.vue';

export default {
  name: 'CreateTemplate',
  metaInfo: {
    title: 'Create Template',
  },
  components: {
    Whatsapp,
    Whatsapp_personal,
    Sms,
  },
  computed: {
    activeChannel() {
      return this.$store.state.channels.activeChannel;
    },
  },
};
</script>
